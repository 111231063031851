import * as React from "react";
import styled from "styled-components";
import PageWrapper from "../components/shared/PageWrapper";
import ContentDiv from "../components/shared/ContentDiv";
import {
  LocalStorageInterface as LSI,
  ScreenSizes,
  createLogOutTimer,
  isSmallScreen,
  parseActiveAccount,
} from "../shared-code";
import backgroundImage from "../images/web-bg.jpg";
import { AccountInfo } from "../components/DataModels";
import { HeraldAPI } from "../api-calls";

const StyledTextContainer = styled.div`
  max-width: 1025px;
  padding-inline: 10px;

  & h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    margin-block: 30px;
  }

  & h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin-block: 30px;
  }

  & p,
  ol,
  ul {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }

  & .bold {
    font-weight: 600;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 40px 0;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  padding-inline: 25px;

  @media (max-width: ${ScreenSizes.narrow}) {
    justify-content: center;
  }
`;

const SubHeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 10px 20px;
  height: 38px;
  cursor: pointer;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 16px;
    padding: 5px 10px;
  }
`;

const subHeader = () => (
  <SubHeaderContainer>
    <a href="/privacy">
      <SubHeaderButton>Privacy</SubHeaderButton>
    </a>
    <a href="/tos">
      <SubHeaderButton>Terms Of Service</SubHeaderButton>
    </a>
    <a href="mailto:support@starform.co">
      <SubHeaderButton>Support</SubHeaderButton>
    </a>
  </SubHeaderContainer>
);

interface MyState {
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  timeOutNumber: number | undefined;
}

export default class Tos extends React.Component<{}, MyState> {
  state: MyState = {
    accounts: LSI.getAccounts(),
    activeAccountId: LSI.getActiveAccountId(),
    timeOutNumber: undefined,
  };

  componentDidMount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const starTokenExpiration = LSI.getStarTokenExpiration();
    if (starTokenExpiration != undefined) {
      const timeOutNumber = createLogOutTimer(
        starTokenExpiration,
        window,
        location
      );
      this.setState({ timeOutNumber: timeOutNumber });
    }
    HeraldAPI.getPvPStats(
      "SA4uhJJsqQUms3.k",
      () => {},
      () => {}
    );
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (prevState.accounts == undefined && this.state.accounts != undefined) {
      if (this.state.timeOutNumber != undefined) {
        window.clearTimeout(this.state.timeOutNumber);
      }
      const starTokenExpiration = LSI.getStarTokenExpiration();
      if (starTokenExpiration != undefined) {
        const timeOutNumber = createLogOutTimer(
          starTokenExpiration,
          window,
          location
        );
        this.setState({ timeOutNumber: timeOutNumber });
      }
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  render() {
    const activeAccount = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );
    return (
      <PageWrapper
        subHeader={subHeader()}
        activeRoute=""
        fullScreen={false}
        forceLogin={false}
        backgroundImage={backgroundImage}
        accounts={this.state.accounts}
        activeAccount={activeAccount}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({ activeAccountId: account.accountId });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
      >
        <ContentDiv
          smallDevice={isSmallScreen()}
          hasSubHeader={true}
          fullScreen={false}
        >
          <StyledTextContainer>
            <h1>STARFORM TERMS OF SERVICE</h1>
            <p>
              These Terms of Service are a binding agreement between you and
              Starform, Inc., its subsidiaries and affiliated companies
              (“Starform”) that governs your use of the Service. The “Service”
              means (i) the website located at www.starform.co (the “Site”),
              including any services, features and content accessible or
              downloadable from the Site, and (ii) any other Starform
              application, service or product licensed, downloaded or otherwise
              accessed by you through third party websites or sources. These
              Terms of Service include and hereby incorporate Starform' Privacy
              Policy, which is currently located at https://starform.co/privacy.
            </p>
            <h2>1. Your Agreement to these Terms of Service</h2>
            <p>
              Please carefully read these Terms of Service. By registering for
              an Account or otherwise using the Service, you represent that (1)
              you are age 13 or older, (2) you understand and agree to these
              Terms of Service, and (3) if you are between the ages of 13 and
              18, your legal guardian has reviewed and agrees to these Terms of
              Service.
            </p>
            <p>
              BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE
              TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF
              SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE
              SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.{" "}
            </p>
            <h2>2. Changes to the Terms of Service and the Service</h2>
            <p>
              Starform reserves the right, at its discretion, to change, modify,
              add or remove portions of these Terms of Service and its Privacy
              Policy at any time by posting the amended terms on the Site. You
              will be deemed to have accepted such changes by continuing to use
              the Service. Except as otherwise expressly stated, all amended
              terms shall automatically be effective immediately when posted.{" "}
            </p>

            <p>
              These Terms of Service may not be otherwise amended except in a
              writing signed by you and Starform. For purposes of this
              provision, “writing” does not include an e-mail message and a
              signature does not include an electronic signature. If at any
              point you do not agree to any portion of the then-current version
              of the Terms of Service, the Privacy Policy, or any other Starform
              policy or rules relating to your use of the Service, your license
              to use the Service shall immediately terminate, and you must
              immediately stop using the Service. To the extent the Terms of
              Service or Privacy Policy conflict with any other terms, policy or
              rules of Starform, the terms contained in these Terms of Service
              and in the Privacy Policy shall govern.
            </p>

            <p>
              Service and in the Privacy Policy shall govern. Except as may be
              expressly specified otherwise by Starform with respect to paid
              portions of the Service, Starform reserves the right to add,
              change, suspend or discontinue the Service, or any aspect or
              feature of the Service, without notice or liability.
            </p>

            <h2>3. Limited License Grant and Restrictions</h2>
            <p>
              Starform grants to you a limited, non-exclusive, non-transferable,
              non-sublicensable revocable license to use and display the
              following: (a) the portions of the Service that are freely
              accessible from the Starform Site or that are, with Starform'
              authorization, made freely accessible from third party websites or
              sources, and (b) such other portions of the Service accessible on
              a “for-payment” basis, provided that you have paid the applicable
              fees and satisfied all applicable conditions. The license in the
              preceding sentence is (i) solely for your personal, non-commercial
              use; (ii) solely for a single computer to access the Site and
              solely on a device to access applications; and (iii) subject to
              your compliance at all times with these Terms of Service.
            </p>

            <p>
              You agree not to (and not to attempt to): (i) copy, adapt, modify,
              prepare derivative works based upon, distribute, license, sell,
              transfer, publicly display, publicly perform, transmit, stream,
              broadcast or otherwise exploit the Service or any portion of the
              Service, except as expressly permitted in these Terms of Service;
              or (ii) use the Service for any use or purpose other than as
              expressly permitted by these Terms of Service. Neither Starform
              nor any of the Starform Parties (as defined below) grant to you
              any licenses or rights except for the licenses and rights
              expressly granted in these Terms of Service.
            </p>

            <h2>4.Registration, User Data and Accounts:</h2>
            <p>
              You may register to play our games through the Service (the
              “Account”). As part of this registration process, you may be asked
              to provide information about yourself in order to use the Service.
              You agree to provide and maintain (updating as necessary) true,
              accurate, complete and current information as requested by the
              Service. If you provide any information that is untrue,
              inaccurate, incomplete or not current, Starform has the right to
              suspend or terminate your Account and refuse any and all current
              or future use of the Service by you.
            </p>

            <p>
              You are responsible for maintaining the confidentiality of your
              Account. You are fully responsible for all activities that occur
              under your Account with or without your knowledge. You agree to
              notify Starform immediately of any unauthorized use of your
              Account or any other breach of security related to your Account.
            </p>

            <h2>5. Your Content; Service Materials</h2>
            <p>
              As between you and Starform, Starform and its licensors own and
              will retain ownership of all right, title and interest in and to
              the Service and the Service Materials, except for Your Content (as
              defined below) and except as otherwise expressly set forth in
              these Terms of Service. You shall not acquire any ownership rights
              whatsoever by downloading Service Materials or by purchasing any
              Virtual Currency or Virtual Goods (each as defined below).
            </p>

            <p>
              You agree that the Service Materials are protected by, and their
              use, copying and dissemination may be restricted by, applicable
              intellectual property and other laws in both the United States and
              other jurisdictions. All rights not expressly granted by these
              Terms of Service are reserved by Starform and its licensors, and
              no license is granted hereunder by estoppel, implication or
              otherwise. You agree not to encumber, license, modify, publish,
              copy, sell, transfer, transmit or in any way exploit, any portion
              of the Service or Service Materials other than Your Content (as
              defined below), nor will you attempt to do so, except as expressly
              permitted in writing by Starform and, as applicable, the owner of
              such Service Materials (from whom you are solely responsible for
              obtaining permission).
            </p>

            <p>
              “Service Materials” means all information and materials that are
              part of the Service, including without limitation the following:
              any and all copyrightable material, including software; the “look
              and feel” of the Service or portions thereof; the compilation,
              assembly and arrangement of the materials of the Service or
              portions thereof; photographs; graphics; layout; text; images;
              audio; video; designs; advertising copy; data; logos; domain
              names; trademarks, service marks, trade names and other source
              identifiers; and User Content.
            </p>

            <h2>6. Trademarks</h2>
            <p>
              Starform The term “Starform,” the Starform logo and other
              Starform, Inc. logos and product and service names are trademarks
              and service marks of, and are owned by, Starform. You may not use
              or display such trademarks in any manner, including without
              limitation using such trademarks as “metatags,” except as
              expressly set forth in these Terms of Service. All third party
              trademarks and service marks appearing on the Service are the
              property of their respective owners and all rights therein are
              reserved.
            </p>

            <h2>7. User Content (including Your Content)</h2>
            <p>
              The Service may invite or enable you and other users to create,
              submit, record, post, display, transmit, perform, publish or
              distribute communications (including but not limited to voice
              communications), content and materials (including without
              limitation text, writings, photographs, graphics, images,
              comments, chat text, personally identifiable information, etc.),
              including by making the foregoing available to Starform and other
              users of the Service, whether via e-mail or through online forums,
              chat rooms or features, message boards, messaging services, blogs
              or other functionality of the Service or portions thereof
              (collectively, “User Content”). Starform has no obligation to
              accept, display, review, maintain or otherwise exploit any User
              Content.
            </p>

            <p>
              You understand that all User Content available in connection with
              the Service is the sole responsibility of the person from whom
              such User Content originated. Starform has no obligation to
              pre-screen, review, examine, evaluate or otherwise monitor any
              User Content for accuracy, validity, legality, decency, integrity
              or any other quality. Starform makes no, and hereby disclaims any
              and all, warranties or other guarantees with respect to User
              Content. You understand that your use of the Service is at your
              own risk and that by using the Service, you may be exposed to User
              Content that is offensive, indecent, objectionable or that does
              not otherwise meet your needs. You bear all risks associated with
              the use of any User Content available in connection with the
              Service. Starform shall not be liable in any way for any User
              Content made available via the Service, including, but not limited
              to, any errors or omissions in any such User Content, or any loss
              or damage of any kind incurred as a result of the use of such User
              Content.
            </p>

            <p>
              User Content that you make available in connection with the
              Service is referred to herein as “Your Content.” You agree that
              Your Content is not confidential. You further agree that Your
              Content will not be returned to you. You represent and warrant
              that Your Content is original to you and that you exclusively own
              the rights to Your Content, including the right to grant all of
              the rights and licenses in these Terms of Service without Starform
              incurring any third party obligations or liability arising out of
              its exercise of such rights and licenses. Starform does not claim
              any ownership rights in Your Content and nothing in these Terms of
              Service will be deemed to restrict any rights that you may have to
              use and exploit Your Content. Starform has no obligation to
              monitor or enforce your intellectual property rights in or to Your
              Content.
            </p>

            <p>
              Starform shall not be responsible for another user's misuse or
              misappropriation of any of Your Content. Notwithstanding the
              foregoing, Starform reserves the right in its sole discretion to
              pre-screen, review, monitor, refuse, remove from the Service,
              censor, edit, alter, delete, disable access to or otherwise make
              unavailable any User Content (including without limitation Your
              Content) without notice for any reason, including without
              limitation the violation of these Terms of Service, or for no
              reason, at any time. You may bring User Content that you believe
              violates these Terms of Service, or other inappropriate user
              behavior, to Starform's attention by submitting a support message
              at support@starform.co
            </p>

            <p>
              You hereby grant to Starform a worldwide, irrevocable, perpetual,
              non-exclusive, transferable, royalty-free license, (with the right
              to sublicense), to use, copy, adapt, modify, distribute, license,
              sell, transfer, publicly display, publicly perform, transmit,
              stream, broadcast and otherwise exploit for any purpose Your
              Content via the Service or by any other means. You also hereby
              grant to Starform the right to sublicense and authorize others to
              exercise any of the rights granted to Starform under these Terms
              of Service. You further hereby irrevocably grant to Starform the
              unconditional right to use and exploit your name, voice, persona
              and likeness included in any User Content and in connection with
              any User Content, without any obligation to you. Except as
              prohibited by law, you waive any rights of attribution and/or any
              moral rights you may have in Your Content, regardless whether Your
              Content is altered or changed in a manner not agreeable to you.
            </p>

            <h2>8. Restrictions and Rules of Use</h2>
            <p>
              The following restrictions and rules apply to your use of the
              Service. As a condition of your use of the Service, and without
              limiting your other obligations under these Terms of Service, you
              agree to comply with the restrictions and rules set forth in this
              Section as well as any additional restrictions or rules (such as
              application-specific rules) set forth in the Service itself.
            </p>

            <ul>
              <li>
                You shall not create an Account or access the Service if you are
                under the age of 13 (or at least 16, if you reside in the EU)
              </li>
              <li>
                You shall monitor your Account to restrict use by minors, and
                you will deny access to children under the age of 13. You accept
                full responsibility for any unauthorized use of your Account by
                minors.
              </li>
              <li>
                You are responsible for any use of your credit card or other
                payment instrument by minors You shall not use your Account for
                any commercial purpose, including without limitation, (a) any
                attempt to raise money for any party or any purpose or
                advertise, (b) promote or attempt to trade or sell a website,
                pyramid scheme or any other product or service of any kind, or
                (c) perform market research on the Service
              </li>
              <li>
                You shall not use your Account to advertise, or solicit, or
                transmit any commercial advertisements, including chain letters,
                junk e-mail or repetitive messages (e.g., spam) to anyone
              </li>
              <li>
                You shall not use your Account to engage in any illegal conduct
              </li>
              <li>
                You shall not rent, lease, sell, trade or otherwise transfer
                your Account or any virtual items or virtual currency associated
                with your Account to anyone without Starform's prior written
                permission
              </li>
              <li>
                You shall not submit false refund requests to Starform or third
                party platform
              </li>
            </ul>
            <p>You further agree not to:</p>

            <ol type="i">
              <li>
                interfere with or disrupt the Service or servers or networks
                connected to the Service, or disobey any requirements,
                procedures, policies or regulations of networks connected to the
                Service;
              </li>
              <li>
                interfere with, disrupt or circumvent any security feature of
                the Service or any feature that restricts or enforces
                limitations on use of or access to the Service;
              </li>
              <li>
                use the Service to intentionally or unintentionally violate any
                applicable local, state, national or international law;
              </li>
              <li>use the Service to harm minors in any way;</li>
              <li>
                use the Service to reveal any personal information about another
                individual, including any information that may be used to track,
                contact or impersonate that individual;
              </li>
              <li>defraud or mislead Starform or other users;</li>
              <li>
                impersonate any person or entity or falsely state or otherwise
                misrepresent your affiliation with a person or entity;
              </li>
              <li>
                create any Account by automated means or false pretenses or use
                any other user's Account for any purpose, including to
                circumvent a suspension or ban; or
              </li>
              <li>
                cheat or use, develop or distribute automation software programs
                (“bots”), “macro” software programs or other “cheat utility”
                software program or applications which are designed to modify
                the Starform experience to the detriment of fair play;
              </li>
              <li>
                behave inappropriately, abuse or mistreat other players. This
                includes, but is not limited to, publishing or creating any
                usernames, posts, or messages that use language that is racist,
                sexist, homophobic, ableist, discriminatory, threatening, or
                abusive.
              </li>
            </ol>
            <p>
              You also agree to comply with all applicable laws or other rules
              regarding online conduct and acceptable User Content, and you
              agree to comply with all applicable laws or other rules regarding
              the transmission of technical data exported from the United States
              or the country in which you reside.
            </p>

            <p>
              In addition, you agree not to use the Service or any portion of
              the Service to commit actions that Starform considers, in its sole
              discretion, to be detrimental in any way to the Service or to any
              user's enjoyment of the Service.
            </p>

            <p>
              Starform strives to build and maintain a diverse and inclusive
              community for all players. Starform is dedicated to helping ensure
              that our games and communities are in the spirit of our
              anti-racist, anti-discriminatory, and anti-abuse beliefs. Starform
              reserves the right to determine what conduct it considers to be in
              violation of the rules of use or otherwise outside the spirit of
              these Terms of Service or the Service itself and to take action as
              a result, which may include termination of your Account and
              exclusion from further participation in the Service.
            </p>

            <p>
              You are responsible for any fees, including data, access, and
              usage fees charged by an internet provider or mobile carrier, that
              you incur when accessing the Service.
            </p>

            <h2>9. Virtual Currency and Virtual Goods</h2>
            <p>
              The Service may include virtual currency, such as coins, points or
              similar items that may be earned or obtained through the Service
              or otherwise purchased by you for legal tender or actual currency,
              subject to applicable law (“Virtual Currency”). The Service may
              also include virtual digital items, such as commodities, abilities
              or other goods that may be earned or obtained through the Service
              or otherwise purchased by you for legal tender or actual currency,
              or for Virtual Currency, subject to applicable law (“Virtual
              Goods”). Starform reserves the absolute right, at any time and at
              its sole discretion, to manage, regulate, control, modify or
              eliminate Virtual Currency and/or Virtual Goods. Starform shall
              have no liability to you or any third party in the event that
              Starform exercises any such rights. You have no right, tile or
              interest in or to any such Virtual Goods or Virtual Currency
              appearing or originating in the Service except the following: You
              will have a limited, personal, non-transferable,
              non-sublicensable, revocable license to use, solely within the
              Service, Virtual Goods and Virtual Currency that you have earned,
              purchased or otherwise obtained in a manner authorized by
              Starform.
            </p>

            <p>
              You agree that the transfer of Virtual Currency and Virtual Goods
              is prohibited except where expressly authorized in the Service.
              Also, outside of the Service, you shall not sell, redeem or
              otherwise transfer Virtual Currency or Virtual Goods to Starform,
              any other user of the Service or any other party.
            </p>

            <p>
              You agree that all sales of Virtual Currency and Virtual Goods are
              final and non-refundable, unless Starform or the applicable third
              party platform decides in its sole and absolute discretion to
              provide a refund. You agree that in the event that these Terms of
              Service, your Account or the Service is terminated for any reason,
              which may include without limitation Starform's discontinuation
              for any reason of the applicable portion of the Service, you will
              forfeit all Virtual Currency and Virtual Goods and Starform will
              have no liability to you in connection with that forfeiture.
            </p>

            <h2>10. Feedback</h2>
            <p>
              "Feedback" means any comments, suggestions or feedback about, or
              in connection with, the Service that you provide to Starform. You
              agree that any Feedback shall be the exclusive property of
              Starform, and you hereby assign all rights, title and interest in
              and to such Feedback to Starform. You agree that unless otherwise
              prohibited by applicable law, Starform may use, sell, disclose and
              otherwise exploit the Feedback in any way and for any purpose,
              without compensation to you.
            </p>

            <h2>11. Termination</h2>
            <p>
              You may terminate your Account at any time and for any reason by
              submitting a support message at support@starform.co
            </p>

            <p>
              Starform may terminate these Terms of Service, your Account and
              your access to the Service (or, at Starform's sole option,
              applicable portions of the Service) at any time and for any
              reason. In addition, Starform may notify authorities or take any
              actions it deems appropriate (including without limitation
              suspending your Account and your access to the Service), without
              notice to you if Starform suspects or determines that you may have
              (i) failed to comply with any provision of these Terms of Service
              or any policies or rules established by Starform; or (ii) engaged
              in actions relating to or in the course of using the Service that
              may be illegal or cause liability, harm, embarrassment,
              harassment, abuse or disruption for you, Starform, any third
              parties or the Service itself.
            </p>

            <p>
              You may, as the result of termination, lose your Account and all
              information and data associated therewith, including without
              limitation your user names, avatars, characters and achievements,
              Virtual Currency and Virtual Goods. You will not be entitled to
              and Starform will not be liable to you or any third party for any
              refund, reimbursement or other liability as a result of any
              termination permitted under these Terms of Service for any reason,
              whether by you or Starform. Starform reserves the right to refuse
              to keep Accounts for or provide the Service to any individual. You
              shall not allow individuals whose Accounts have been terminated by
              Starform to use your Account.
            </p>
            <p>
              These Terms of Service will remain effective until terminated. You
              agree that the following sections of these Terms of Service will
              survive any termination of these Terms of Service, your Account or
              the Service: Section 5 (Your Content; Service Materials), Section
              6 (Trademarks), Section 7 (User Content (including Your Content)),
              Section 9 (Virtual Currency and Virtual Goods), Section 10
              (Feedback), Section 11 (Termination), Section 12 (Disputes with
              Others), Section 13 (Disclaimers of Warranties and Damages,
              Limitations of Liability), Section 14 (Indemnification), Section
              15 (Governing Law and Remedies), Section 16 (Links to Third Party
              Websites) and Section 17 (Miscellaneous Provisions).
            </p>

            <h2>12. Disputes with Others</h2>
            <p>
              Starform reserves the right, but has no obligation, to monitor and
              manage disputes between you and other users of the Service. You
              are solely responsible for your interaction with other users of
              the Service and other parties that you come in contact with
              through the Service. You will cooperate fully with Starform to
              investigate any suspected unlawful, fraudulent or improper
              activity, including, without limitation, granting Starform access
              to any password-protected portions of your Account. Starform
              hereby disclaims any and all liability to you or any third party
              relating to any dispute between you and other users of the
              Service.
            </p>

            <h2>
              13. Disclaimers of Warranties and Damages, Limitations of
              Liability
            </h2>
            <p>
              YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK
              AND IS PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY
              KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF NON-INFRINGEMENT,
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
            </p>

            <p>
              WITHOUT LIMITING THE FOREGOING, NEITHER Starform NOR ITS
              AFFILIATES OR SUBSIDIARIES, OR ANY OF THEIR DIRECTORS, EMPLOYEES,
              AGENTS, ATTORNEYS, THIRD-PARTY CONTENT PROVIDERS, DISTRIBUTORS,
              LICENSEES OR LICENSORS (COLLECTIVELY, “Starform PARTIES”) WARRANT
              THAT (A) THE SERVICE WILL MEET YOUR REQUIREMENTS OR THE SERVICE
              MATERIALS OR USER CONTENT WILL BE VIEWABLE TO YOU OR (B) THE
              SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE OR
              THAT ERRORS WILL BE CORRECTED.
            </p>

            <p>
              UNDER THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE Starform
              PARTIES SHALL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY
              INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE
              OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES,
              LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER
              INTANGIBLE LOSSES, ARISING OUT OF OR RELATING IN ANY WAY TO THESE
              TERMS OF SERVICE OR THE SERVICE, WHETHER BASED ON WARRANTY,
              CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY,
              AND WHETHER OR NOT ANY Starform PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </p>

            <p>
              UNDER NO CIRCUMSTANCES WILL THE Starform PARTIES BE LIABLE TO YOU
              FOR MORE THAN THE AMOUNT YOU HAVE PAID TO Starform IN ACCORDANCE
              WITH THESE TERMS OF SERVICE IN THE NINETY (90) DAYS IMMEDIATELY
              PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM. YOU
              ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID Starform ANY SUCH
              AMOUNTS IN THE NINETY (90) DAYS IMMEDIATELY PRECEDING THE DATE ON
              WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND EXCLUSIVE
              REMEDY FOR ANY DISPUTE WITH Starform IS TO STOP USING THE SERVICE
              AND TO CANCEL YOUR ACCOUNT.
            </p>

            <p>
              Some jurisdictions do not allow the exclusion of certain
              warranties or the limitation or exclusion of liability for certain
              types of damages. Accordingly, some of the above limitations and
              disclaimers may not apply to you. To the extent that Starform or
              any other Starform Party may not, as a matter of applicable law,
              disclaim any warranty or limit its liability as set forth herein,
              the scope and duration of such warranty and the extent of
              Starform' and such Starform Party's liability shall be the minimum
              permitted under such applicable law.
            </p>

            <h2>14. Indemnification</h2>
            <p>
              You agree to indemnify, defend and hold Starform Parties harmless
              from and against any and all claims, liabilities, damages, losses,
              costs, expenses, fees (including reasonable attorneys' fees and
              court costs) due to or arising from (1) information in your
              Account and any information you (or anyone accessing the Service
              using your Account) submit, post or transmit through the Service,
              (2) your (or anyone accessing the Service using your Account) use
              of the Service, (3) your (or anyone accessing the Service using
              your Account) violation of these Terms of Service, and (4) your
              (or anyone accessing the Service using your Account) violation of
              any rights of any other person or entity. Starform reserves the
              right, at your expense, to assume the exclusive defense and
              control of any indemnifiable matter and you agree to cooperate
              with Starform to defend these claims.
            </p>

            <h2>15. Governing Law and Remedies</h2>
            <p>
              These Terms of Service and any action related thereto or to the
              Service will be governed by the laws of the State of Washington
              without regard to its conflict of laws provisions. The exclusive
              jurisdiction and venue of any action with respect to the subject
              matter of these Terms of Service will be the state and federal
              courts located in Seattle, Washington, and each of the parties
              hereto waives any objection to jurisdiction and venue in such
              courts. The parties specifically disclaim application of the
              United Nations Convention on Contracts for the International Sale
              of Goods. You acknowledge that the rights granted and obligations
              made to Starform under these Terms of Service are of a unique and
              irreplaceable nature, the loss of which may result in immediate
              and irreparable harm to Starform for which remedies at law are
              inadequate. Starform shall therefore be entitled to seek
              injunctive or other equitable relief (without the obligation to
              post any bond) in the event of any breach or anticipatory breach
              by you. You hereby irrevocably waive all rights to seek injunctive
              or other equitable relief.
            </p>

            <h2>16. Links to Third Party Websites</h2>
            <p>
              The Service may contain links to third party websites or
              resources. You acknowledge and agree that Starform is not
              responsible or liable for: (i) the availability or accuracy of
              such websites or resources; or (ii) the content, products, or
              services on or available from such websites or resources. Links to
              such websites or resources do not imply any endorsement by
              Starform of such websites or resources or the content, products,
              or services available from such websites or resources. You
              acknowledge sole responsibility for and assume all risk arising
              from your use of any such websites or resources. Starform may
              remove any links at any time for any reason or for no reason.
            </p>

            <h2>17. Miscellaneous Provisions</h2>
            <ol type="a">
              <li>
                Access and Availability. You agree to be responsible for
                obtaining and maintaining all telephone, computer hardware,
                mobile devices and any other equipment needed for access to and
                use of the Service, and all charges related thereto. Starform
                operates and controls the Service from its offices in the United
                States. The information and materials provided on the Service
                are not intended for distribution to or use by any person or
                entity in any jurisdiction where such distribution or use would
                be contrary to law or regulation or which would subject Starform
                to any registration requirement within such jurisdiction or
                country.
              </li>

              <li>
                Entire Agreement. These Terms of Service, Privacy Policy, and
                additional Starform' published policies and rules, constitute
                the entire agreement between you and Starform with respect to
                your use of the Service and any other subject matter hereof,
                supersede all prior understandings of the parties, whether
                electronic, oral or written, or whether established by custom,
                practice, policy or precedent and cannot be changed or modified
                by you except as expressly posted on the Service by Starform.
              </li>

              <li>
                No Waiver. The failure of Starform to exercise or enforce any
                right or provision of these Terms of Service shall not
                constitute a waiver of such right or provision, and no waiver by
                either party of any breach or default hereunder shall be deemed
                to be a waiver of any preceding or subsequent breach or default.
                If any provision of these Terms of Service is found by a court
                of competent jurisdiction to be invalid, the parties
                nevertheless agree that the court should endeavor to give effect
                to the parties' intentions as reflected in the provision, and
                the other provisions of these Terms of Service shall remain in
                full force and effect.
              </li>

              <li>
                Assignment. These Terms of Service may not be assigned by you
                without Starform' prior written consent, but are freely
                assignable by Starform.
              </li>

              <li>
                Compliance. Upon Starform' request, you will furnish Starform
                any documentation, substantiation or releases necessary to
                verify your compliance with these Terms of Service.
              </li>

              <li>
                Construction and Waiver of Defenses. You agree that these Terms
                of Service will not be construed against Starform by virtue of
                having drafted them. You hereby waive any and all defenses you
                may have based on the electronic form of these Terms of Service
                and the lack of signing by the parties hereto to execute these
                Terms of Service.
              </li>

              <li>
                Force Majeure. Starform shall not be liable for any delay or
                failure to perform resulting from causes outside its reasonable
                control, including without limitation any failure to perform
                hereunder due to acts of God, war, terrorism, riots, embargoes,
                acts of civil or military authorities, fire, floods, accidents,
                strikes, or shortages of energy.
              </li>
            </ol>
          </StyledTextContainer>
        </ContentDiv>
      </PageWrapper>
    );
  }
}
